import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {graphql, StaticQuery} from 'gatsby'
import '../styles/style.scss';
import Header from './header'
import Footer from './footer';

const Layout = ({children}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {name: 'description', content: 'DD Zone, POS, Point of Sale'},
            {name: 'keywords', content: 'DD Zone, POS'},
            {name: 'facebook-domain-verification', content: 'm8p96mew9i1mzvn0mf9untoyzp94uw'},
          ]}
        >
            <html lang="en"/>
        </Helmet>

          <section className="section-main">
          <Header/>
          <div className='main-body'>
            {children}
          </div>

          <Footer/>
        </section>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
